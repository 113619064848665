.event-home-root{
    background-color: #151851;
    margin: 0;
    // background-image: -webkit-linear-gradient(-45deg, #8a2be2 0%, #b537f2 50%, #3cb9fc 100%);

    .box-root{
        border-radius: 10px;
        padding: 3em;
        display: flex;
        background-image: -webkit-linear-gradient(45deg, #dd1177 0%, #505add 100%);
        position: relative;
    }

    .box-root .img-container{
        position: absolute;
        top: 4em;
        right: -2em;
    }

    .title-text{
        font-weight: 600;
        margin-top: 1em;
    }

    .post-evnt-btn{
        background-color: #0e0e0e;
    }
    .post-evnt-btn:hover{
        background-color: #1d131f;
    }

    .search-container{
        margin: auto;
    }

    .search-container .title-text{
        margin-bottom: 1em;
        color: #fefefe;
        text-decoration: underline;
        text-underline-offset: 0.5em;
    }

    .search-tab{
        justify-content: space-between;
        align-items: center;
        margin: 2em 0em;
    }

    .search-tab > * {
        width: 100%;
        background-color: #fefefe !important;
        height: 3em;
        padding: 0 0.5em;
        border-radius: 6px !important;
        font-size: 14px !important;
    }

    .search-button{
        background-color: #21aa12 !important;
        color: #fefefe;
        width: 50%;
    }

    .event-paginator{
        display: flex;
        justify-content: center;
        padding: 2em;
    }

    .MuiPaginationItem-root{
        color: #fefefe !important;
    }

    .Mui-selected{
        background-color: #dd1177 !important;
    }
}

@media screen and (min-width: 480px){
    .event-home-root{
        padding-top: 6em;
    }
    .box-root{
        margin-left: 2em;
        margin-right: 2em;
    }
}

@media screen and (min-width: 600px){
}

@media screen and (min-width: 900px){
    .event-home-root{
        padding-top: 8em;
    }
    .box-root{
        margin-left: 5em;
        margin-right: 5em;
    }
}

@media screen and (min-width: 1200px){
    .event-home-root{
        padding-top: 10em;
    }
    .box-root{
        margin-left: 10em;
        margin-right: 10em;
    }
}
