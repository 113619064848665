.search-table-root{
    margin-bottom: 5em;

    .toolbar-root{
        color: #fefefe;
        background-color: black;
        // background-image: -webkit-linear-gradient(120deg, #505add 0%, #dd1177 80%);
        border-radius: 8px 8px 0px 0px;
        min-height: 5em;
        padding: 1em 2em;

        .content-box{
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .toolbar-heading{
            font-weight: 600;
            margin-right: 2em;
        }
    }

    .table-container{

        max-height: 36em;
        background-color: #fefefe;

        .header-title{
            font-weight: 600;
        }
    }

    .table-footer-root{
        background-color: #fefefe;
        border-radius: 0px 0px 8px 8px;

        .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
            margin: 0;
         }
    }

}
