.applicant-brief-root{
    position: relative;
    height: 24em;
    width: 100%;

    .apt-bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: 40%;
        object-fit: cover;
    }

    .svg-drop{
        position: absolute;
        width: 10em;
        height: 5em;
        top: 6.7em;
        left: 0em;
        z-index: 5;
        background-color: currentColor;
    }

    .apt-avatar{
        position: absolute;
        width: 3.5em;
        height: 3.5em;
        top: 6em;
        left: 2.2em;
        z-index: 6;
    }

    .apt-brief{
        position: absolute;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        padding: 1em;
        top: 11em;
        left: 0;
        z-index: 5;
    }

    .apt-heading{
        font-weight: 600;
    }

    .apt-buttons{
        position: absolute;
        height: auto;
        width: 100%;
        padding: 1em;
        bottom: 0;
        left: 0;
        z-index: 5;
    }
}
