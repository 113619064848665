.manage-events{

    padding: 8em 5em;
    background-image: -webkit-linear-gradient(-60deg, #6aeea6 20%, #505add 100%);

    .title{
        font-weight: 600;
    }

    .new-event-btn{
        color: #fefefe;
        background-color: #0e0e0e !important;
        padding: 1em 5em;
        border-radius: 3em;
    }
}

@media screen and (max-width: 900px){

    .manage-events{
        .new-event-btn{
            margin-top: 2em;
        }
    }

}
