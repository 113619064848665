
.popup-root{
    margin: 3em 1em 1em 1em;

    .title{
        text-align: left;
        font-size: 3rem !important;
        font-weight: 600 !important;
        letter-spacing: 0.7rem !important;
        font-family: inherit !important;
        // margin: 1em 0em 0.2em 0em !important;
    }

    .content{
        padding: 2em;
        color: #0e0e0e !important;
        /* background-image: -webkit-linear-gradient(120deg, #505add 0%, #dd1177 100%); */
        background-color: #fefefe !important;
        border-radius: 10px !important;
    }

    .detail-title{
        font-size: 20px !important;
        font-weight: 600 !important;
        font-family: inherit !important;
        margin-bottom: 0.5em !important;
    }

    .detail-input{
        /* outline: #eeeeee auto 2px; */
        border: 2px solid #eeeeee;
        border-radius: 5px;
        width: 100%;
        padding: 0.5em 0.8em !important;
        font-family: inherit !important;
        margin-bottom: 1.5em !important;
    }

    .tox-tinymce{
        margin-bottom: 1.5em !important;
    }

    .location-chips{
        margin-bottom: 1em !important;
    }

    .event-dates{
        margin-bottom: 2em;
    }

    .event-apl-date{
        margin-bottom: 1.5em !important;
    }

    .MuiAutocomplete-root .MuiInputBase-root{
        // border: 2px solid #eeeeee;
        border-radius: 5px !important;
        font-family: inherit !important;
        margin-bottom: 1em !important;
        width: 100%;
    }

    // uncomment below if applying border on autocomplete.
    // .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline{
    //     border:none !important;
    // }

    // uncomment below if applying border on autocomplete.
    // .MuiOutlinedInput-notchedOutline:hover{
    //     border: none !important;
    // }

    .amount-type-dropdown{
        height: 3.3em;
        width: 12em;
    }

    .action-buttons{
        padding: 1.5em 2.5em;

        button{
            width: 15em;
            margin-left: 2em;
        }
    }

}


@media screen and (max-width: 600px) {
    .popup-root{
        .content{
            padding: 2em;
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
    .popup-root{
        .content{
            padding: 2em 4em;
        }
    }
}
