.home-root {
    background-image: -webkit-linear-gradient(240deg, #dd1177 0%, #151851 100%);
    padding: 10em 5em;

    .home-heading {
        font-weight: 600;
        color: #fefefe;
    }

    .home-subtitle {
        color: #fefefe;
        padding: 2em 0em;
    }

    .gs-btn {
        font-weight: 600;
        color: #fefefe;
        border-radius: 20px;
        border: 1px solid #fefefe ;
    }
    .gs-btn:hover {
        color: #0e0e0e;
        background-color: #fefefe;
    }

    .img-container {
        width: 100%;
    }

    .custom-img {
        max-width: 100%;
        height: auto;
    }

}


.about-root {
    position: relative;
    background-color: #fefefe;
    padding: 5em 8em;
    z-index: 1;

    .about-heading {
        font-weight: 500;
        position: relative;
    }

    .about-heading::after {
        display: block;
        content: "";
        height: 2px;
        width: 75px;
        background-color: #21aa12;
        position: relative;
        top: 0.5em;
    }

    .about-content {
        font-size: 18px;
    }
}


.how-it-works-root {
    position: relative;
    padding: 2em 8em;
    color: #fefefe;
    background-image: -webkit-linear-gradient(120deg, #dd1177 0%, #151851 100%);
    overflow: hidden;

    .rotor {
        position: absolute;
        width: 20em;
        height: 20em;
        left: calc(50% + 30em);
        top: -8em;
        transform: translate(-50%, 0);
        animation: rotate 100s infinite linear;
    }

    .planes {
        position: absolute;
        width: 20em;
        height: auto;
        left: calc(50% - 50em);
        bottom: 1em;
        transform: scaleX(-1);
        opacity: 0.25;
    }

    .step-heading{
        display: flex;
        justify-content: center;
        padding-top: 0.5em;
        padding-bottom: 1em;
    }

    .step-heading::after {
        display: block;
        content: "";
        height: 2px;
        width: 2em;
        background-color: #21aa12;
        position: absolute;
        transform: translate3d(2em, 1.4em, 0)
    }

    .step-root {
        width: 100%;
        text-align: justify;

        .step {
            font-family: inherit;
            font-weight: 600;
        }

        .step-description {
            font-family: inherit;
            font-weight: 400;
        }
    }

}


.ethics-root{
    padding: 4em 8em 2em 8em;
    /* background-color: #21aa12; */
    text-align: justify;
    color: #0e0e0e;

    .ethics-heading{
        display: flex;
        font-weight: 600;
        justify-content: center;
        padding: 0.5em 0em;
        position: relative;
    }

    .ethics-heading::after {
        display: block;
        content: "";
        height: 3px;
        width: 1em;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: #21aa12;
    }

    .ethics-body{
        padding-top: 2em;
        text-align: justify;
    }

}


.features-root {
    /* background-color: #3d1d4d; */
    /* background-image: -webkit-linear-gradient(120deg, #dd1177 0%, #151851 100%); */
    padding: 2em 8em 5em 8em;

    .features-title {
        position: relative;
        text-align: center;
        padding-bottom: 2em;
    }

    .features-title::after {
        display: block;
        content: "";
        height: 2px;
        width: 3em;
        position: absolute;
        left: 50%;
        bottom: 1em;
        transform: translateX(-50%);
        background-color: #21aa12;
    }

    .feature-img {
        max-width: 20em;
        height: auto;
    }

    .features-heading {
        padding-top: 1em;
        padding-bottom: 0.5em;
        align-items: center;
    }

    .features-sub-heading {
        font-weight: 300;
        font-size: 16px ;
        text-align: justify ;
        margin-left: 2.5em;
    }
}


.newsletter-root{
    margin: 5em;
    padding: 2em 8em;
    background: -webkit-linear-gradient(white, white) padding-box,
                -webkit-linear-gradient(120deg, #505add 10%, #dd1177 100%) border-box ;
    border-radius: 1em !important;
    border: 3px solid transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1200px;

    .newsletter-title{
        font-weight: 600 !important;
        margin-bottom: 0.5em !important;
    }

    .newsletter-body{
        margin-bottom: 0.5em !important;
    }
}


@media screen and (max-width: 900px) {
    .home-root {
        padding: 8em 2em;
    }

    .how-it-works-root {
        padding: 4em 2em;
    }

    .about-root {
        padding: 4em 2em;
    }

    .features-root {
        padding: 4em 2em;
        text-align: justify !important;
    }

    .ethics-root{
        padding: 4em 2em 0em 2em;
        text-align: center;
    }

    .newsletter-root{
        margin: 5em 2em;
        padding: 2em 2em;
    }

}


#tsparticles {
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translate(-50%);
    width: inherit;
    height: -webkit-fill-available;
    background-color: #000000 !important;
}

@keyframes rotate {
    from {
        transform: rotate(1deg);
    }

    to {
        transform: rotate(360deg);
    }
}
