.default-navbar {
    /* background-color: transparent !important; */
    background-color: #0e0e0e !important;
    transition: box-shadow .3s ease-out !important;
    width: 100%;
    z-index: 9999;
}

.default-navbar-elevated {
    background-color: #0e0e0e !important;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(20 20 20 / 25%), 0rem 0.125rem 0.25rem -0.0625rem rgb(20 20 20 / 10%);
    width: 100%;
    transition: box-shadow .3s ease-out !important;
    z-index: 9999;
}

.custom-navbar-root{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.logo-container img{
    transition: max-width 0.3s ease-out 0s !important;
}

.menu-mobile{
    height: fit-content;
    width: fit-content;
    color: #fefefe !important;
}

.page-navigator > button {
    color: #fefefe;
    margin: 0.5em 1em;
    font-weight: 600;
    font-family: Poppins, "Public Sans", sans-serif !important;
}

.profile-navigator {
    margin: 0.5em 2em !important;
    display: flex;
}

.page-navigator > button:focus,
.profile-navigator > button:focus,
.menu-mobile > button:focus,
.profile-avatar:focus {
    outline: none;
}


@media screen and (max-width: 900px) {
    .img-container {
        width: 100%;
        justify-content: center;
    }

    .profile-navigator{
        margin: 0.5em !important;
    }
}

@media screen and (min-width: 900px) {
    .page-navigator>button:hover {
        color: #dd1177;
    }
}
