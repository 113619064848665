.event-card-root{
    /* font-family: Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
    /* font-family: "montserrat-medium",sans-serif; */
    background-color: transparent !important;
    border: 2px solid #dd1177;
    padding: 0.5em;

    .event-title{
        font-weight: 600;
        color: #fefefe;
    }

    .event-badge{
        display: flex;
        margin: 0 1em;
        padding: 12px 6px;
        height: 1em;
        align-items: center;
        justify-content: center;
        background-color: #21aa12 !important;
        color: #fefefe !important;
    }

    .event-location{
        font-size: 12px;
        font-weight: 400;
        color: #d3d3d3;
        margin-left: 5px;
    }

    .event-date{
        color: #d3d3d3;
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
    }

    .event-tags{
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5em;
    }

    .company-desc{
        font-size: 14px;
        font-weight: 400;
        color: #d3d3d3 !important;
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        text-align: justify;
        /* width: 75%; */
    }

    .event-card-actions{
        padding: 1em;
        min-width: max-content;
        max-width: 30em;
        margin: auto !important;
        justify-content: space-evenly;
        align-items: center;
    }

    .event-card-actions div > *{
        text-align: center;
        margin: 0.5em !important;
        border-radius: 7px !important;
        padding: 0.5em 3em;
        font-weight: 400;
        // color: #fefefe !important;
    }

    .event-card-actions > *:hover{
        cursor: pointer;
        // text-decoration: underline;
    }

    .view-event{
        background-image: -webkit-linear-gradient(120deg, #505add 10%, #dd1177 100%);
        // background-image: -webkit-linear-gradient(-45deg, #8a2be2 0%, #b537f2 50%, #3cb9fc 100%);
        // background-image: -webkit-linear-gradient(-45deg, #6aeea6 0%, #3cb9fc 100%);
        color: #fefefe;
    }

    .save-event{
        background-color: #1d1e1f !important;
        color: #fefefe;
    }

    .hide-event{
        background-color: transparent !important;
        border: 1px solid #dd1177;
        color: #fefefe;
    }
}

/*
@media screen and (max-width: 700px){
    .event-card-actions{
        display: flex !important;
        flex-direction: column !important;
    }
}

@media screen and (min-width: 900px){
    .event-card-actions{
        display: flex !important;
        flex-direction: column !important;
    }
} */
