.event-dashboard{
    background-color: #f1f1f1;
}
.dashboard-root {
    padding: 1em 5em;

    .heading{
        margin: 2em 0em 0.2em 0em;
        font-weight: 600;
    }

    .subheading{
        margin: 1em 0em 0.5em 0em;
    }
}

@media screen and (max-width: 900px) {
    .dashboard-root {
        padding: 1em 2em;
    }
}

@media screen and (min-width: 1200px){
    .dashboard-root {
        padding: 1em 8em;
    }
}
