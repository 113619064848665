.footer-root{
    background-color: transparent;
    padding: 5em 10em;

    .footer-title{
        text-align: center;
        font-weight: 600;
        color: #fefefe;
    }

    .footer-subtitle{
        text-align: center;
        color: #dd1177;
    }

    .card-group{
        align-items: center;
        justify-content: center;
    }

    .info-card{
        background-color: #1d1e1f;
        padding: 4em 4em;
        color: #fefefe;
        min-height: 45em;
        width: 100%;
        max-width: 400px;

        .card-heading{
            font-weight: 600;
            margin-bottom: 5em;
            color: #fefefe;
            text-decoration: underline;
            text-underline-offset: 0.5em;
        }

        .card-subheading{
            color: #21aa12;
            font-weight: 600;
        }

        .card-text{
            font-weight: 200;
        }
    }

    .translucent{
        background-color: #1d1e1faa;
    }

    .form-group .MuiInputBase-root{
        color: #d3d3d3;
    }

    .form-group .MuiInputBase-root::before{
        border-color: #d3d3d3;
    }

    .form-group .MuiInputBase-root:hover::before{
        border-bottom: 1px solid #777777;
    }

    .form-group .MuiInputBase-root::after{
        border-color: #dd1177;
        transition: all .2s ease-in-out 0s;
    }

    .submit-form{
        background-color: #0e0e0e;
    }

    .social-media-icon{
        color: #777777;
    }
    .social-media-icon:hover{
        color: #fefefe;
    }

}


@media screen and (min-width: 480px){
    .footer-root{
        padding: 5em 2em;
    }
    .card-group{
        margin: 5em 1em !important;
    }
}

@media screen and (min-width: 600px){
    .card-group{
        margin: 5em 2em !important;
    }
}

@media screen and (min-width: 900px){
    .footer-root{
        padding: 5em;
    }
    .card-group{
        margin: 5em 4em !important;
    }
}

@media screen and (min-width: 1200px){
    .footer-root{
        padding: 5em;
    }
    .card-group{
        margin: 5em !important;
    }
}

#tsparticles {
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translate(-50%);
    width: inherit;
    height: -webkit-fill-available;
    background-color: #000000 !important;
}
