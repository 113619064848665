.navbar-root{
    position: absolute;
    display: flex;
    font-family: Poppins, sans-serif !important;
    background-color: transparent;
    align-items: center;
    justify-content: space-between;
    margin: 1em auto;
    padding: 0.2em;
    width: 80%;
    max-width: 1536px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 1em;
    border: 0.0625rem solid rgba(226, 232, 240, 0.3);
    backdrop-filter: blur(40px);
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(20 20 20 / 12%), 0rem 0.125rem 0.25rem -0.0625rem rgb(20 20 20 / 7%);
    z-index: 5;
    color: #fefefe;
}

/* .company_logo{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='white' stroke-width='2' stroke-dasharray='100%2c 10' stroke-dashoffset='40' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 7px;
    padding: 0.5em 1em;
    color:#fefefe;
} */

.pages > *{
    font-weight: 200 !important;
    font-family: inherit !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
}

.pages > *:hover{
    border: 1px solid #fefefe !important;
}

button[disabled] {
    background-image: unset !important;
}
