.info-card-root{
    background-color: #fefefe;
    border-radius: 10px !important;
    padding: 1em 2em;
    min-height: 7.5em;

    .info-title{
        font-weight: 400;
    }

    .info-value{
        font-weight: 600;
    }
}
