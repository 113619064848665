.auth-root{
    background-image: -webkit-linear-gradient(120deg, #1d1e1f 0%, #3d1d4d 80%);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;

    .img-container{
        height: auto;
        flex-grow: 1;
    }

    .bg-img{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
    }

    .sign-in-form{
        height: auto;
        flex-grow: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 8em;
        color: #fefefe;
    }

    .sign-up-form{
        height: auto;
        flex-grow: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 8em;
        color: #fefefe;
    }

    .form-heading{
        color: #fefefe;
        font-weight: 600 !important;
    }

    .form-sub-heading{
        color: #fefefe;
    }

    .form-root{
        padding: 2em;
        width: auto;
        max-width: 30em;
    }

    .form-field{
        color: #fefefe;
        margin-top: 2em !important;
        margin-bottom: 0.5em !important;
        margin-left: 0.25em !important;
    }

    .form-input{
        font-size: 14px !important;
        padding: 0.5em 1em;
        background-color: #1d1e1f;
        border: 1px solid #dd1177;
        border-radius: 20px;
        color: #fefefe !important;
        transition: border-color 150ms ease !important;
    }

    .form-input:focus-within{
        box-shadow: 0em 0em 0em 0.125em rgb(98, 143, 194);
        border-color: rgba(226, 232, 240, 0.6);
    }

    .login-btn {
        font-family: Poppins, sans-serif !important;
        font-weight: 600 !important;
        color: #0e0e0e !important;
        border-radius: 15px !important;
        background-color: #ee88ee !important;
        margin-top: 4em !important;
        padding: 0.5em 4em !important;
    }

    .login-btn:hover {
        background-color: #ee88ee !important;
    }

    .login-btn:disabled {
        background-color: #ffbbdd !important;
    }

    .signup-btn{
        font-family: Poppins, sans-serif !important;
        font-weight: 400 !important;
        display: flex;
        color: #fefefe !important;
        margin-top: 1em;
        align-items: baseline;
        justify-content: center;
    }

    .sign-up-text{
        color: #fefefe;
        font-family: Poppins, "Public Sans", sans-serif !important;
        font-weight: 200 !important;
        font-size: 12px !important;
        margin-top: 5em !important;
        margin-bottom: 0.5em !important;
        margin-left: 0.25em !important;
        text-align: center;
    }

    .helper-text{
        color: #fefefe;
        font-family: Poppins, "Public Sans", sans-serif !important;
        font-weight: 200 !important;
        font-size: 12px !important;
        margin-left: 1em !important;
        margin-top: 0.5em !important;
        cursor: pointer;
        width: fit-content;
    }

    .error-line{
        display: flex;
        font-size: 12px !important;
        align-items: center;
        justify-content: left;
    }
}
